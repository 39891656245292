<template>
        <div
            id="g-recaptcha"
            class="g-recaptcha"
            :data-sitekey="sitekey"
            :data-callback="captchacheck"
         >
        </div>
</template>
<script>
   // import {  findLive  } from "@/js/testHelpers.js";
   // import { getCaptchaRules } from "@/js/validators.js";

    export default {
    components: {
     // VueRecaptcha,
    },
    props:['wsKey'],
    data () {
      return {
        sitekey: this.wsKey,
       // sitekey: findLive()===true?"6LffAygTAAAAADUqaL0xyqqrF4l6WxxVmpzXUF2b":"6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
        widgetId: 0
      };
    },
    computed: {
      captchacheck() {
        return false;
       },
    },
    methods: {
      checkCaptcha(response){
        //console.log("Button was clicked");
        this.$emit('verify', response);
      },
      execute () {
         window.grecaptcha.execute(this.widgetId);
      },
      reset () {
        window.grecaptcha.reset(this.widgetId);
      },
      render () {
        console.log(window.grecaptcha);
        if (window.grecaptcha) {
          this.widgetId = window.grecaptcha.render('g-recaptcha', {
            sitekey: this.sitekey,
              size: 'invisible',
            // the callback executed when the user solve the recaptcha
          callback: (response) => {
              console.log(response);
               this.$emit('verify', response);
               this.reset();
            },
           }
          );
        }
      }
    },
    mounted () {
      window.ReCaptchaLoaded = this.render;
      let recaptchaScript = document.createElement('script');
      recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js','async','defer');
     //  recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit');
      document.head.appendChild(recaptchaScript);
      //render the recaptcha widget when the component is mounted
     //this.render();
    }
  }
</script>