<template>
  <div>
      <recaptcha ref="recaptcha"
                 @verify="checkResult"
                 :wsKey="result['websiteKey']"
      ></recaptcha>
  </div>
</template>

<script>
import recaptcha from "@/components/legacy/recaptcha";

export default {
  props: ["result"],
  components: {
    recaptcha,
  },
  data() {
    return {
      captchaChecked: false,
      // sitekey: process.env.RECAPTCHA_PUBLIC_KEY,
    };
  },
  created() {
    this.$emit("changeIsValid", false);
  },
  methods: {
    checkResult(response) {
      console.log("after Click", response);
      this.$emit("changeIsValid", true);
    },
  },
};
</script>
<style>
.captchaError {
  border: solid 1px red;
}
</style>


<!---template>
  <div>
    <fieldRowHeader></fieldRowHeader>
    <fieldName></fieldName>

    <td v-if="typeof result.fieldsFormat!='undefined'" v-for="(fieldsFormatAttr, fieldsFormatName) in result.fieldsFormat.fields"{$fieldsFormatName}="{$fieldsFormatAttr}" {/foreach} {/if}>
    {$fieldAttrInput=getfieldAttrInput(fieldAttributes)}
    <div class="captcha_wrapper">
      <div class="g-recaptcha" data-sitekey="{result.websiteKey}" data-callback="imNotARobot"></div>
      <input type="text" name="reCaptchaHidden" class="checkCaptcha validatedHidden" />
    </div>
  </div>
</template>

<script src='https://www.google.com/recaptcha/api.js?hl=en'></script>

<script>
var imNotARobot = function () {
  // here come once the button checked !
  $(".captcha_wrapper").removeClass("borderClass");
  $("input[name='reCaptchaHidden']").val("loaded");
};
$(document).ready(function () {
  $(".submit").on("click", function () {
    var googleResponse = $("#g-recaptcha-response").val();
    if (typeof googleResponse != "undefined" && googleResponse.length == 0) {
      $(".captcha_wrapper").addClass("borderClass");
    } else {
      $(".captcha_wrapper").removeClass("borderClass");
    }
  });
});
</script--->
